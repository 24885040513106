import post1Image from '../assets/images/dafaultImage1.jpg';
import post2Image from '../assets/images/dafaultImage2.jpg';
import post3Image from '../assets/images/dafaultImage3.jpg';
import post4Image from '../assets/images/dafaultImage4.jpg';
import post5Image from '../assets/images/dafaultImage5.jpg';
import post6Image from '../assets/images/dafaultImage6.jpg';
import post7Image from '../assets/images/dafaultImage7.jpg';
import post8Image from '../assets/images/dafaultImage8.jpg';
import post9Image from '../assets/images/dafaultImage9.jpg';
import post10Image from '../assets/images/dafaultImage10.jpg';
import post11Image from '../assets/images/dafaultImage11.jpg';
import post12Image from '../assets/images/dafaultImage12.jpg';

export const defaultImagesList = [
    {
        image: post1Image,
    },
    {
        image: post2Image,
    },
    {
        image: post3Image,
    },
    {
        image: post4Image,
    },
    {
        image: post5Image,
    },
    {
        image: post6Image,
    },
    {
        image: post7Image,
    },
    {
        image: post8Image,
    },
    {
        image: post9Image,
    },
    {
        image: post10Image,
    },
    {
        image: post11Image,
    },
    {
        image: post12Image,
    },
]